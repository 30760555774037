import React from 'react'
import { Head } from '~/components'
import Menu from '~/components/Menu'
import Banner from '~/components/Banner'

import bannerContato from '~/assets/img/banner-contato.jpg'
import Footer from '~/components/Footer'
import Newsletter from '~/components/Newsletter'
import Contact from '~/components/Contact'
import useScroll from '~/components/useScroll'

const Contato = () => {
  useScroll()
  return (
    <>
      <Head />
      <Banner
        image={bannerContato}
        title="Precisando falar com a Dimas Sempre?"
        buttonText="Envie uma mensagem"
        anchor="/contato/#atendimento"
        maxWidth={800}
      />
      <Contact />
      <Newsletter />
      <Footer />
    </>
  )
}

export default Contato
