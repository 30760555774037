// extracted by mini-css-extract-plugin
export var address = "Contact-module--address--DsEkZ";
export var attendance = "Contact-module--attendance--Wyjvz";
export var contactContainer = "Contact-module--contact-container--Ihtjq";
export var divider = "Contact-module--divider--PKd2J";
export var formWrapper = "Contact-module--form-wrapper--JXfDx";
export var gatsbyFocusWrapper = "Contact-module--gatsby-focus-wrapper--+b4O-";
export var hideOnDesktop = "Contact-module--hide-on-desktop--QqOmd";
export var hideOnMobile = "Contact-module--hide-on-mobile--8Nqqn";
export var iconCar = "Contact-module--icon-car--lAvlW";
export var iconCars = "Contact-module--icon-cars--r8JoS";
export var iconClock = "Contact-module--icon-clock--jQzts";
export var iconEletricCar = "Contact-module--icon-eletric-car--vwinS";
export var iconFacebook = "Contact-module--icon-facebook--nW6yc";
export var iconGear = "Contact-module--icon-gear--E4YMe";
export var iconInstagram = "Contact-module--icon-instagram--IHxNB";
export var iconKm = "Contact-module--icon-km--5LMML";
export var iconPhone = "Contact-module--icon-phone--cilSS";
export var iconRight = "Contact-module--icon-right--gwme2";
export var iconWhatsapp = "Contact-module--icon-whatsapp--KpT1-";
export var informations = "Contact-module--informations--msKk8";
export var pageWidth = "Contact-module--page-width--g2sc2";
export var path1 = "Contact-module--path1--dOmhL";
export var path2 = "Contact-module--path2--Pk65v";
export var path3 = "Contact-module--path3--7Gdud";
export var privacy = "Contact-module--privacy--nvITg";
export var view = "Contact-module--view--708ob";
export var views = "Contact-module--views--nNMDt";
export var work = "Contact-module--work--vQ1Ei";