import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import cn from 'classnames'

import requestData from '~/siteApi/form.js'

import * as st from '~/assets/styl/Contact.module.styl'

import formatPhone from '~/utils/formatPhone'
import { useCookies } from 'react-cookie'
import { navigate } from 'gatsby'

export default function Contact() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [privacy, setPrivacy] = useState(false)
  const [communications, setCommunications] = useState(false)
  const [termo, setTermo] = useState(false)
  const [cookies] = useCookies()
  const terms_of_use = termo
  const privacy_policy = privacy
  const Celular = phone

  function handleName(e) {
    setName(e.target.value)
  }

  function handleEmail(e) {
    setEmail(e.target.value)
  }

  function handlePhone(e) {
    setPhone(formatPhone(e.target.value))
  }

  function handleMessage(e) {
    setMessage(e.target.value)
  }

  function handleTermo(e) {
    setTermo(e.target.checked)
    setPrivacy(e.target.checked)
  }

  function handleComunicacao(e) {
    setCommunications(e.target.checked)
  }

  function handleSubmit(e) {
    e.preventDefault()
    setName('')
    setEmail('')
    setPhone('')
    setMessage('')
    requestData('post', 'conversions', {
      name,
      email,
      Celular,
      message,
      legal_bases: [
        {
          category: 'communications',
          type: 'consent',
          status: 'granted',
        },
        {
          category: 'data_processing',
          type: 'consent',
          status: 'granted',
        },
      ],
      token_rdstation: '36ef8b1445846a96de8abe06eaf566be',
      identificador: 'Site - Fale conosco',
      traffic_source: cookies?.['__trf.src'] || cookies?.__utmzz,
      __utmz: cookies?.['__trf.src'] || cookies?.__utmzz,
    }).then((data) => {
      if (data.result === 'success') navigate('/obrigado')
      else alert(data.msg)
    })
  }

  const isMobile = useMediaQuery({ query: '(max-width: 1080px)' })

  return (
    <div id="atendimento" className={cn(st.contactContainer, 'page-width')}>
      <section className={st.informations}>
        <h2>Entre em contato com a nossa equipe</h2>
        <p>
          Utilize o formulário e envie uma mensagem para nossos consultores.
        </p>
        <section className={st.attendance}>
          <h3>Atendimento Dimas Sempre</h3>
          <a
            href="https://wa.me/+5548999470082"
            target="_blank"
            rel="noreferrer external nofollow"
          >
            <span className="icon-whatsapp"></span>
            Chame no WhatsApp
          </a>
        </section>
        <section className={st.address}>
          <h3>Nosso escritório</h3>
          <address>
            Av. Mar Max Schramm nº 2700
            <br />
            Estreito, Florianópolis - CEP 88095-000
          </address>
          <a
            href="https://www.google.com/maps/dir//Concession%C3%A1ria+Ford+Dimas+-+Estreito+-+Av.+Marinheiro+Max+Schramm,+2700+-+Jardim+Atl%C3%A2ntico,+Florian%C3%B3polis+-+SC,+88095-000/@-27.5789907,-48.5888661,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x9527482f6037938b:0xe95afeedbe74c46b!2m2!1d-48.5888661!2d-27.5789907!3e0"
            target="_blank"
            rel="noreferrer external nofollow"
          >
            Traçar uma rota no Google
          </a>
        </section>
        <a className={st.work} href="http://www.dimas.com.br/trabalhe-conosco">
          Trabalhe Conosco
        </a>
      </section>
      {isMobile && <div className={st.divider}></div>}
      <form onSubmit={handleSubmit}>
        <div className={st.formWrapper}>
          <label>
            Nome
            <input
              type="text"
              placeholder="Qual seu nome?"
              value={name}
              onChange={handleName}
              required
            />
          </label>
          <label>
            E-mail
            <input
              type="email"
              placeholder="Digite seu melhor e-mail"
              value={email}
              onChange={handleEmail}
              required
            />
          </label>
          <label>
            Whatsapp
            <input
              type="tel"
              placeholder="Qual seu numero para contato?"
              value={phone}
              onChange={handlePhone}
              maxLength={15}
              required
            />
          </label>
          <label>
            Mensagem
            <textarea
              value={message}
              placeholder="Insira sua mensagem"
              cols={30}
              rows={10}
              onChange={handleMessage}
            ></textarea>
          </label>
        </div>
        <div className={st.privacy}>
          <label>
            <input
              id="use_terms"
              type="checkbox"
              name="termo"
              onChange={handleTermo}
              required
            />
            <small>
              Eu concordo em receber comunicações. Ao informar meus dados, eu
              concordo com a
              <AnchorLink to="/politica-de-privacidade/">
                Política de privacidade
              </AnchorLink>
              e<AnchorLink to="/termos-de-uso/">Termos de uso</AnchorLink>
            </small>
          </label>
          <label>
            <input
              id="use_terms"
              type="checkbox"
              name="comunicacao"
              onChange={handleComunicacao}
              required
            />
            <small>
              Autorizo o Grupo Dimas a armazenar meus dados pessoais para enviar
              campanhas de marketing e informações sobre a empresa nos canais:
              Telefone, Email e SMS.
            </small>
          </label>
        </div>
        <button type="submit">Enviar</button>
      </form>
    </div>
  )
}
